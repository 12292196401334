.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--black);
  font-weight: 500;
}

body {
  background-color: var(--dashboard-bg);
}

.logoMain {
  cursor: pointer;
}

.mainHeader {
  position: sticky;
  top: 0;
  background-color: var(--header-bg);
  display: flex;
  align-items: center;
  left: 0;
  right: 0;
  z-index: 99;
  height: 83px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-bottom: 3px solid var(--turquoise);
  box-sizing: border-box;
}

/* .sideBarMenu {} */

.thirdHeaderMain {
  background-color: var(--white);
  position: sticky;
  top: 83px;
  z-index: 99;
  box-shadow: 0px 6px 12px #00000029;
  transition: all 0.5s;
}

.thirdHeaderNew {
  background-color: white;
  position: sticky;
  top: 80px;
  z-index: 99;
  color: var(---header-bg);
  height: 80px;
  display: flex;
  align-items: center;
}
.breadcrumb-lead{
  justify-content: space-between;
}
.thirdHeaderNew span{
  font-family: "jokker-medium" !important;
}
.secondHeader {
  height: 100px;
  background-color: var(--header-bg);
  border-top: 3px solid var(--turquoise);
}

.insurance_box {
  cursor: pointer;
}

.insurance_box div {
  height: 100px;
  width: 100px;
  background-color: var(--white);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.insurance_combo_box {
  background-color: var(--white);
  border-radius: 12px;
  display: flex;
  padding: 25px;
  align-items: center;
  gap: 15px;
  position: relative;
}

.insurance_combo_box_main p {
  color: var(--header-bg);
  font-family: "jokker-semibold";
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 18px;
  text-align: center;
}

.insurance_combo_box_rec {
  padding: 8px;
  border-radius: 8px;
  background-color: var(--yellow);
  color: var(--header-bg);
  font-family: "jokker-regular";
  font-size: 16px;
  position: absolute;
  top: -15px;
  left: 0;
}

.insurance_box p {
  color: var(--header-bg);
  font-family: "jokker-semibold";
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 18px;
  text-align: center;
}

.secondHeaderItem {
  display: flex;
  align-items: center;
  gap: 20px;
}

.secondHeader h4 {
  font-weight: bold;
  font-size: 28px;
  font-family: "bunch-bold";
  background-color: var(--header-bg);
}

.headerLink {
  display: flex;
  gap: 20px;
  align-items: center;
}

.headerMenu {
  display: flex;
  justify-content: space-between;
  gap: 123px;
}

.headerLink {
  display: flex;
}

.headerLink a,
.headerLink .link {
  cursor: pointer;
  text-transform: capitalize;
  font-size: 18px;
  /* font-weight: 600; */
  color: var(--white);
  font-family: "jokker-medium";
}

.headerLink a:hover,
.headerLink .link:hover {
  color: var(--turquoise);
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--black);
}

.headerLink {
  margin-left: 55px;
}

.loginBtn {
  /* margin-top: 10px; */
  /* margin-left: auto !important; */
  /* width: 125px;
  height: 44px; */
  /* border-radius: 8px; */
  /* border: none;
  color: var(--header-bg);
  font-family: "jokker-medium";
  background-color: var(--turquoise); */
  font-size: 18px;
  /* font-weight: 600; */
  cursor: pointer;
  transition: all 0.3s;
  /* box-shadow: 4px 4px 12px #ebebeb29 ; */
}
.loginBtn:hover{
  /* background-color: white; */

}

.menuDropLinksMain {
  transition: 0.5s;
  cursor: pointer;
}

.menuDropLinksMain:hover {
  color: var(--yellow);
}

.menuDrop {
  display: flex;
  gap: 10px;
  cursor: pointer;
  position: relative;
}

.menuDropMain {
  position: relative;
}

.menuDropLinks {
  position: absolute;
  top: 50px;
  border-top: 2px solid var(--yellow);
  width: 210px;
  background-color: var(--white);
  padding: 16px 10px;
  display: flex;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  gap: 35px;
}

.menuDropMain {
  display: flex;
}

.userInfo {
  cursor: pointer;
  margin-left: auto;
  display: flex;
  padding: 8px;
  gap: 8px;
  align-items: center;
  border: 1px solid var(--white);
  border-radius: 8px;
}

.profileUserName {
  cursor: pointer;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 400;
  color: var(--white);
  font-family: "jokker-medium";
}

.badgeContent {
  background-color: var(--yellow);
  color: var(--header-bg);
  margin-top: -3px;
  margin-right: -2px;
}

/* .profileUserName:after {
  display: inline-block;
  margin: 0px 10px;
  padding-top: 5px;
  vertical-align: middle;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  font-size: 20px;
  border-left: 0.3em solid transparent;
} */

.userImg {
  border-radius: 100px;
  background-color: var(--yellow);
  height: 30px;
  width: 30px;
}

.secondUserIcon {
  border-radius: 100px;
  background-color: var(--yellow);
  height: 60px;
  width: 60px;
  font-size: 24px;
  font-weight: 600;
  font-family: "jokker";
  color: var(--header-bg);
}



.thirdHeader {
  height: 80px;
  display: flex;
  align-items: center;
  background: var(--white);
}

.thirdHeader a {
  color: var(--header-bg);
  font-size: 18px;
  font-family: "jokker-medium";
  letter-spacing: 0px;
  opacity: 1;
  padding: 15px;
}

.thirdHeader .active {
  background-color: var(--header-bg);
  border-radius: 12px;
  color: var(--white);
}

.thirdHeader .disableLinkMainPage {
  cursor: not-allowed;
  background-color: transparent;
  color: #09143a;
}

.avatarUser {
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  font-family: "jokker-medium";

}
.userInfo .userImg {
  /* background-color: var(--dashboard-bg); */
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px !important;
}

.userInfo h4 {
  margin: 0;
  padding: 0;
}

.mainSideBar {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 340px;
  height: -webkit-fill-available;
  height: -moz-available;
  padding: 30px;
  background-color: var(--header-bg);
}

.sideBarUser {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.sideBarUser .profileUserName {
  margin: 0;
  color: var(--white);
  font-family: "bunch-bold";
  font-size: 27px;
}

.sideBarUser .sideBarUserIcon {
  color: var(---header-bg);
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  font-family: "Jokker-Regular";
  height: 60px;
  width: 60px;
  border-radius: 100px;
  background-color: var(--yellow);
}

.sideBarTopMenu {
  display: flex;
  flex-direction: column;
  border-top: 0.5px solid var(--sapphire-blue);
  padding-top: 29px;
  margin-top: 29px;
}

.sideBarTopMenu a,
.sideBarTopMenu span,.sideBarBottomMenu li {
  cursor: pointer;
  color: var(--white);
  font-size: 16px;
  font-family: "jokker-medium";
  padding: 21px 0px 21px 30px;
}

.sideBarTopMenu a:hover,
.sideBarTopMenu span:hover {
  color: var(--header-bg);
  background-color: var(--white);
  border-radius: 12px;
}

.disableLink {
  cursor: not-allowed;
  color: var(--white);
  font-size: 16px;
  font-family: "jokker-regular";
  padding: 21px 0px 21px 30px;
}

.disableLink:hover {
  color: var(--header-bg);
  background-color: var(--white);
  border-radius: 12px;
}

.sideBar-login {
  border-top: 0.5px solid var(--sapphire-blue);
  padding-top: 29px;
  margin-top: 29px;
  text-align: center;
}

.sideBarMenu {
  display: flex;
  flex-direction: column;
  border-top: 0.5px solid var(--sapphire-blue);
  padding-top: 29px;
  margin-top: 29px;
}

.sideBarMenuList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sideBarMenu a {
  color: var(--white);
  font-size: 16px;
  font-family: "jokker-regular";
  padding: 21px 0px 21px 30px;
}

.sideBarMenu a:hover {
  color: var(--header-bg);
  background-color: var(--white);
  border-radius: 12px;
}

.sideBarBottomMenu {
  display: flex;
  flex-direction: column;
  /* border-top: 0.5px solid var(--sapphire-blue);
  padding-top: 29px;
  margin-top: 29px; */
}

.sideBarBottomMenu li,
.sideBarBottomMenu li span {
  padding: 21px 0px 21px 30px;
  font-size: 16px;
  font-family: "jokker-regular";
}

.sideBarBottomMenu li:hover,
.sideBarBottomMenu li span:hover {
  color: var(--header-bg);
  background-color: var(--white);
  border-radius: 12px;
}

.sideBarBottomMenu li:hover a {
  color: var(--header-bg);
}

.sideBarBottomMenu li:hover span {
  color: var(--header-bg);
}

.sideBarBottomMenu li a,
.sideBarBottomMenu li span {
  color: var(--white);
}

.notFoundContain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.notFoundContain p {
  color: var(--header-bg);
  text-align: center;
  font-size: 18px;
  font-family: "jokker-regular";
  margin: 40px 0px 20px 0px;
}

.exploreBtn {
  color: var(--header-bg);
  font-size: 18px;
  font-family: "jokker-regular";
  background: var(--yellow);
  border-radius: 8px;
  min-width: 129px;
  height: 53px;
  border: unset;
  transition: 0.5s;
  display: block;
  margin: auto;
}

.exploreBtn:hover {
  background-color: var(--header-bg);
  color: var(--white);
}

/* .mainSideBar li {
  padding: 10px;
} */
/* .mainSideBar .active {
  position: relative;
  background-color: var(--white);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;
  display: block;
} */
/* .mainSideBar .active::before {
  content: " ";
  height: 100%;
  width: 3px;
  background-color: var(--yellow);
  position: absolute;
  left: 0;
  top: 0;
} */
.sidebarToggal {
  display: none !important;
}

.sidebarHeader h4 {
  padding: 0;
  margin: 0;
}

.sidebarHeader h4 span {
  color: var(--yellow);
}

.sidebarHeader {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid var(--light-gary);
}

.learnMoreModel {
  width: 60%;
  position: absolute;
  background-color: var(--dashboard-bg);
  border-radius: 10px;
  margin: auto;
  top: 50%;
  position: absolute;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  overflow: hidden;
  padding: 30px;
}

.learnMoreModel:focus-visible {
  outline: none;
}

.contentTitleBox {
  width: 40%;
  padding: 30px;
}

.contentBox {
  width: 60%;
  padding: 30px;
}

.contentBox p {
  font-family: "jokker-regular";
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
  color: var(--black);
  line-height: 1.7rem;
}

.contentTitleBox h2 {
  font-family: "jokker-bold";
  font-size: 32px;
  color: var(--black);
  margin: 0;
  padding: 0;
}

.contentTitleBox h3 {
  font-family: "jokker-regular";
  font-weight: 400;
  color: var(--black);
  margin: 0;
  padding: 0;
  font-size: 22px;
}

.popupBg {
  width: 900px;
  height: 600px;
  background-color: var(--white);
  border-radius: 10px;
  margin: auto;
  top: 50%;
  position: absolute;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  overflow: hidden;
}

.logImgMain {
  width: 50%;
  height: 100%;
  background-color: var(--header-bg);
  position: relative;
}

.closeLoginModalButton {
  position: absolute !important;
  top: 10px;
  right: 10px;
}
.popupContain {
  z-index: 2;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 70px;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
}

.roundShap {
  height: 450px;
  width: 450px;
  background-color: var(--off-gary);
  border-radius: 100%;
  position: absolute;
  left: -70px;
  top: -70px;
  z-index: 0;
}

.formContainMain {
  width: 62%;
  /* padding: 45px; */
  color: var(--header-bg);
  height: max-content;
  overflow-y: auto ;
}
.formContainMain .overflow{
  padding: 45px; 
}
.tabView .MuiBox-root {
  margin-top: 50px;
  padding: 0;
}

.activity-tabs {
  font-family: "jokker-regular";
}

.activity-tabs .css-1a7hnq3-MuiButtonBase-root-MuiTab-root.Mui-selected {
  font-family: "jokker-bold";
}

.error_block {
  display: flex;
  justify-content: center;
  margin: 0px;
  color: red;
  font-weight: 600;
}

.otp-section .error_block {
  margin-top: 20px;
}

.please_wait_block {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otpButton,
.otp-button {
  cursor: pointer;
}

.otpButton {
  width: 80%;
    min-width: 160px;
    height: 60px;
    background-color: var(--yellow);
    color: var(--header-bg);
    border: unset;
    border-radius: 5px;
    transition: 0.5s;
    display: block;
    margin: auto;
    font-family: "jokker-medium";
    font-size: 18px;
    cursor: pointer;
}

.backBtn {
  position: absolute;
  cursor: pointer;
  top: 0;
}

.otp-button {
  margin-top: 30px;
  width: -webkit-fill-available;
  width: -moz-available;
  min-width: 160px;
  height: 60px;
  background-color: var(--yellow);
  color: var(--header-bg);
  border: unset;
  border-radius: 5px;
  transition: 0.5s;
  display: block;
  margin: auto;
  font-family: "jokker-medium";
  font-size: 18px;
  cursor: pointer;
}

.otp-button[disabled] {
  opacity: 0.5;
  margin-top: 30px;
  background-color: var(--yellow);
  cursor: not-allowed;
}

.button-primary:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.otpButton[disabled] {
  opacity: 0.5;
  background-color: var(--yellow);
  cursor: not-allowed;
}

.account-link {
  cursor: pointer;
  text-align: center;
  font-family: "jokker-light";
}

.account-link a {
  color: var(--header-bg) !important;
  font-family: "jokker-bold";
}

.OtpFild {
  border: 1px solid var(--pearl);
  width: 48px;
  height: 48px;
  border-radius: 4px;
  text-align: center;
}

.OtpFildMain {
  margin-top: 40px;
}

.resend {
  color: var(--off-black);
  font-size: 12px;
  text-align: center;
  margin-top: 30px !important;
  margin-bottom: 20px !important;
}

.d-flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.gap-17 {
  gap: 17px;
}

.stap {
  height: 10px;
  width: 10px;
  background-color: var(--ivory);
  border-radius: 10px;
}

.stap-main {
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-top: 40px;
}

.stap-active {
  width: 20px;
  background-color: var(--black-secondary);
}
.position-relative {
  position: relative;
}
.multiEntryCardTitle{
  font-size: 14px;
  font-family: "jokker-bold";
  color: var(--header-bg);
  position: absolute;
  top: -9px;
  background: var(--white);
  left: 20px;
  padding: 0px 10px;
}
.userEdit {
  position: relative;
}

.menu-img {
  width: 35px;
}

.edit-pen {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 18px;
  width: 18px;
  background-color: var(--white);
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 10px;
  border-radius: 100px;
  border: 1px solid var(--black) 000;
}

.notification-icon {
  cursor: pointer;
}

/* loader css */
.loaderArea {
  background-color: var(--black);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
}

.loaderArea::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  animation: ripple 1s ease-in-out infinite forwards;
  border-radius: 50%;
}

.loaderArea::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  animation: ripple 1s 1.3s ease-in-out infinite forwards;
  border-radius: 50%;
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(6);
    opacity: 0;
  }
}
.loaderBg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.73);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999 !important;
}

.card-main-dashboard-Quotes-Card {
  width: 100%;
  flex-wrap: wrap;
  padding: 0 0px;
  box-sizing: border-box;
  /* margin-left: 54px; */
}

.yourPoliciesMainBoxQuotesResponse {
  background-color: white;
  /* width: 344px; */
  /* max-width: 400px; */
  margin: 0px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 12px;
}

.option_card {
  width: 100%;
  background-color: var(--header-bg);
  text-align: center;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 6px 12px #00000008;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  cursor: pointer;
  width: auto;
  transition: 0.5s;
}

.option_card:hover {
  transform: translateY(-10px);
}

.disabled-card {
  background-color: var(--off-white) !important;
}

.option_img {
  height: 50px;
  width: 50px;
  margin: auto;
}

.option_card_header p {
  color: var(--white);
  font-size: 18px;
  font-family: "jokker-bold";
  margin: 0;
}

.option_card_header input {
  height: 24px;
  width: 24px;
  color: var(--white);
}

.loan_ref_box {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
}

.sticky_footer {
  padding: 16px 0;
  background-color: var(--white);
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 99;
}

.profile_fildes_main p {
  margin-bottom: 10px;
  margin-top: 0px;
  font-size: 16px;
  color: var(--header-bg);
  font-family: "jokker-regular";
}

.profile_main_div {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 40px;
}

.profile_fildes {
  width: -webkit-fill-available;
  width: -moz-available;
  padding: 20px;
  border: unset;
  box-shadow: 0px 6px 12px #00000008;
  border-radius: 5px;
  font-size: 16px;
  color: var(--header-bg);
  font-family: "jokker-regular";
}

.insurance_option_radio {
  appearance: none;
  -webkit-appearance: none;
  border: 2px solid var(--white);
  background-color: var(--header-bg);
  border-radius: 50%;
  accent-color: var(--turquoise);
  cursor: pointer;
}

.insurance_option_radio:checked {
  box-shadow: 0 0 0 2px var(--white);
  background-color: var(--turquoise);
  border: 2px solid var(--header-bg);
  /* border-width: 0.2rem; */
}

.profile_fildes:focus {
  outline: unset;
}

.notFoundContain img {
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.sidebarToggal {
  color: var(--white) !important;
  position: absolute !important;
  right: 30px;
  top: 0;
  padding: 0 !important;
}

.header-progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  height: 5px;
  background-color: var(--turquoise) !important;
  z-index: 999;
}

.responsive-header {
  display: none;
}

.insuranceType {
  text-align: left;
  position: absolute;
  top: -1px;
  right: 0px;
  letter-spacing: 0px;
  color: #666563;
  opacity: 1;
  /* padding-left: 208px; */
}

.leadName {
  text-align: left;
  font: "normal normal normal 16px/18px Jokker";
  letter-spacing: 0px;
  color: #666563;
  opacity: 1;
  margin-top: -130px;
  position: absolute;
  font-family: "jokker-regular";
}

.leadUserName {
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
}

.leadFullName {
  font-family: "jokker-bold";
  font-size: 18px;
}

.leadHeadingCard {
  justify-content: space-between;
}

.insuranceTimeStamp {
  color: #666563;
  font-size: 16px;
  font-family: "jokker-regular";
  margin-top: 1px;
}

.leadStatusInfo {
  color: #666563;
  font-family: "jokker-regular";
  font-size: 16px;
}

.purchasedStatus {
  background-color: #54c192;
  color: #09143a;
  width: 63px;
  height: 34px;
  text-align: center;
  line-height: 36px;
  border-radius: 5px;
  /* position: absolute; */
  font-family: "jokker-regular";
  font-size: 16px;
  display: block;
}

.lostLeadStatus {
  display: block;
  background-color: #f06363;
  color: #ffffff;
  width: 63px;
  height: 34px;
  text-align: center;
  line-height: 33px;
  border-radius: 5px;
  /* position: absolute; */
  font-family: "jokker-regular";
  font-size: 16px;
}

.leadStatusMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leadCardDivider {
  border: 1px solid #4b57641a;
  width: 100%;
  margin-top: 40px;
}

.approvedClass {
  color: #fff !important;
  background-color: #54c192 !important;
  margin-left: 5px;
  border-radius: 8px;
}

.pendingClass {
  color: #fff !important;
  background-color: #ffb600 !important;
  margin-left: 5px;
  border-radius: 8px;
}

.rejectedClass {
  color: #fff !important;
  background-color: #f72929 !important;
  margin-left: 5px;
  border-radius: 8px;
}

.ant-drawer-header {
  display: none !important;
}

@media only screen and (max-width: 1350px) {
  .modal-close-icon {
    right: 0px !important;
    top: 0px !important;
    position: relative !important;
    margin-left: auto;
    display: table;
  }

  .modal-close-icon span {
    background-color: #09143a !important;
    border-radius: 100px;
    /* padding: 5px !important; */
    width: 30px !important;
    height: 30px !important;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-close-icon span svg {
    height: 30px !important;
    height: 30px !important;
  }

  .ant-drawer-body .leadManagementDetailsMain,
  .ant-drawer-body .submitQuotesHeading {
    margin-top: unset !important;
  }
}

@media only screen and (max-width: 992px) {
  .toolbar {
    justify-content: space-between;
  }

  .userInfo h4 {
    display: none;
  }

  .userImg {
    height: 38px;
    width: 38px;
  }

  .logoMain {
    width: 90px;
  }

  .mainHeader {
    height: 90px;
  }

  /* .popupBg {
    width: 700px;
  } */

  .roundShap {
    height: 300px;
    width: 300px;
  }

  .thirdHeader {
    display: none;
  }
}

@media only screen and (max-width: 899px) {
  .empty-div {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .formContainMain {
    min-width: 350px;
    padding: 20px;
    width: 100%;
    max-width: 300px;
  }

  .popupBg {
    width: fit-content;
    height: auto;
    max-height: 96vh;
    overflow: auto;
  }

  .logImgMain {
    display: none;
  }
}

@media only screen and (max-height: 400px) {
  .res-modal-title {
    width: 70%;
    margin-top: 66px;
  }
}

@media only screen and (max-height: 500px) {
  .res-create-modal-title {
    margin-top: 270px;
  }

  .popupBg {
    /* max-height: 300px; */
  }

  .res-modal-title {
    margin-top: 140px;
  }
}

@media only screen and (max-height: 400px) {
  /* .res-create-modal-title {
    margin-top: 175px;
  } */
}

@media only screen and (max-width: 768px) {
  .popupBg {
    width: fit-content;
  }

  .popupBg {
    height: auto;
  }

  .OtpFild {
    width: 40px;
    height: 40px;
  }
  .otpVerification {
    min-width: unset;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .submitQuotesMain {
    padding: 20px !important;
  }

  .otp-box-res {
    gap: 10px !important;
  }
}

@media only screen and (max-width: 375px) {
  .formContainMain {
    width: 100%;
    padding: 10px;
    min-width: unset;
    max-width: 300px;
  }

  .resend {
    margin-top: 40px !important;
  }

  .OtpFild {
    width: 30px;
    height: 30px;
  }
}
