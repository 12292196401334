:root {
  --orange: #ffb600;
  --white: #fff;
  --yellow: #ffcd00;
  --off-white: #f5f5f5;
  --gray-scale: #646464;
  --light-black: #1f1f1f;
  --light-white: #fef0d0;
  --black: #09143a;
  --light-gray: #818181;
  --interstellar: #cfcbcb;
  --black-secondary: #3a3a3a;
  --gray: #9b9b9b;
  --ceramic-gray: #525252;
  --ivory: #a3a3a3;
  --dust-gray: #fbfbfb;
  --carbon-black: #7e7e7e29;
  --dark-black: #00000029;
  --cream: #f2f2f2;
  --marble-white: #e5ffe6;
  --bottle-green: #5ba100;
  --cape-white: #f7f7f7;
  --khamam-gray: #9f9f9f;
  --danger-red: #ff0d0d;
  --carme-white: #fff1f1;
  --light-yellow: #dc9d00;
  --peach: #ffeec2;
  --concrete-gray: #666563;
  --alert: #ff5a5a;
  --venial: #ffe3e3;
  --nim-green: #c9fbcb;
  --ponds-white: #e5e5e5;
  --sweet-yellow: #ffe6a5;
  --sweet-pink: #fed7d7;
  --mystick-white: #d8d8d8;
  --charcoal-black: #595959;
  --lantern-green: #23a023;
  --saffron: #ff8100;
  --gypsum-white: #f9f9f9;
  --stone-gray: #aeaeae;
  --olive-white: #fff0cc;
  --black-current: #343741;
  --pearl: #b5b5b5;
  --light-gary: #e0dfdf;
  --off-gary: #fbf4e3;
  --off-black: #282828;
  --mercury: #e6e6e6;
  --silver: #b8b8b8;
  --selective-yellow: #f4b200;
  --barley-white: #fff1d0;
  --dove-gray: #676767;
  --alto: #d4d4d4;
  --dairy-cream: #faeac1;
  --pink-lady: #fff3d6;
  --dusty-gray: #9c9c9c;
  --mercury-white: #e7e7e7;
  --alabaster: #fcfcfc;
  --green: #03c210;
  --selective-light-yellow: #ffb600;
  --light-silver: #ccc;
  --gallery: #eee;
  --off-silver: #b9b8b8;
  --dashboard-bg: #F0F4FB;
  --header-bg: #09143a;
  --chat-bg: #00bdc7;
  --utility-green: #54c192;
  --violet: #1e2e78;
  --utility-red: #f06363;
  --turquoise: #30ebf2;
  --header-link: #4b5764;
  --sapphire-blue: #294fd2;
  --granite-gray: #4b5764;
}

body {
  margin: 0;
  font-family: "Jokker-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black);
}

h1 {
  font-family: "bunch-bold" !important;
}

code {
  font-family: "Jokker-Regular";
}

.world {
  color: var(--header-bg) !important;
}

.hello-world {
  color: var(--header-bg) !important;
}

.button-primary {
  min-width: 160px;
  height: 60px;
  background-color: var(--yellow);
  color: var(--header-bg);
  border: unset;
  border-radius: 5px;
  transition: 0.5s;
  display: block;
  margin: auto;
  font-family: "jokker-medium";
  font-size: 18px;
  cursor: pointer;
}

.h-42 {
  height: 42px;
}

.button-secondary {
  min-width: 160px;
  height: 40px;
  background-color: var(--white);
  color: var(--gray-scale);
  border: unset;
  border-radius: 50px;
  transition: 0.5s;
  display: block;
}

.dark-button {
  background-color: var(--light-black);
}

.button-primary:hover {
  background-color: var(--header-bg);
  color: var(--white);
}

.button-secondary:hover {
  background-color: var(--light-black);
  color: var(--white);
}

.span-primary {
  color: var(--black);
}

.leadManagementDetailsMain {
  /* max-width: 1200px; */
  justify-content: space-between;
  margin: auto;
  align-items: center;
  width: 100%;
}
.leadManagementDetailsMainTitle {
  display: flex;
  align-items: center;
  gap: 10px;
}
.leadDetailsInfo  {
  color: var(--black);
  font-family: "jokker-bold";
  font-size: 24px;
  position: relative;
  /* margin-left: -14px; */
  width: auto;
}
.leadDetailsInfoDrawer.leadDetailsInfo {
  color: var(--white);
}

.leadDetailsStatus {
  background-color: #f06363;
  color: #ffffff;
  width: 100px;
  height: 38px;
  text-align: center;
  line-height: 36px;
  border-radius: 8px;
  font-family: "jokker-medium";
}
.leadDetailsStatusPurchased {
  background-color: #54c192;
  color: #09143a;
  width: 100px;
  height: 38px;
  text-align: center;
  line-height: 36px;
  border-radius: 8px;
  font-family: "jokker-medium";
  display: block;
}

.leadDetailsStatusProvided {
  background-color: #54c192;
  color: #09143a;
  width: 142px;
  height: 38px;
  text-align: center;
  line-height: 36px;
  border-radius: 8px;
  font-family: "jokker-medium";
  display: block;
}

.quoteResponseInfo {
  color: var(--black);
  font-family: "jokker-bold";
  font-size: 24px;
  margin-left: 6%;
  width: auto;
  height: 2px;
}

.viewPolicyDocs {
  border: 1px solid #09143a;
  border-radius: 8px;
  font-family: "Jokker-Medium";
  color: #09143a;
  width: 236px;
  height: 52px;
  /* top: 15px; */
  font-size: 18px;
  margin-left: auto;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  background-color: transparent;
}

.viewPolicyDocs:hover {
  background-color: #09143a;
  color: white;
}

.chatWithLeadBtn {
  background-color: #ffcd00;
  border-radius: 8px;
  font-family: "Jokker-Medium";
  color: #09143a;
  width: 194px;
  height: 52px;
  /* top: 14px; */
  font-size: 18px;
  /* margin-right: -18px; */
  border: none;
  /* position: relative; */
  cursor: pointer;
}
.chatIcon svg {
  height: 35px;
  width: 35px;
  fill: #09143a;
}
.chatIcon {
  display: none;
}
@media screen and (max-width: 1024px) {
  .leadManagementColHeading {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 900px) {
  .leadManagementColHeading {
    font-size: 24px !important;
  }
}

@media screen and (max-width: 600px) {
  .leadDetailsInfo {
    font-size: 18px;
    margin-left: 0;
  }
}

.chatWithLead {
  background-color: var(--yellow);
  border-radius: 8px;
  color: #000;
}

.spanPrimary {
  color: var(--yellow);
}

.primary-bg {
  background-color: var(--yellow);
}

.toggle-button {
  border-radius: 8px !important;
  background-color: var(--yellow) !important;
  color: var(--header-bg) !important;
  border: none !important;
  padding: 10px 20px !important;
  opacity: 50%;
}

.toggle-button-main {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 20px !important;
}

.toggle-button.Mui-selected {
  color: var(--header-bg) !important;
  background-color: var(--yellow) !important;
  opacity: 100%;
}

.form-label-fild {
  font-size: 14px;
  font-weight: 600;
  color: var(--light-gray);
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 20px;
}

.form-outline-border {
  border: 1px solid var(--interstellar);
  padding: 20px;
  border-radius: 10px;
}

.w-136 {
  width: 136px !important;
  min-width: unset !important;
}

.editIcon {
  cursor: pointer;
  font-size: 1.1rem !important;
  font-family: "jokker-Regular";
  border-radius: 8px;
  width: 21%;
  border: #fff;
  position: inherit;
  top: -26px;
}

.editIconQuotesBtn {
  cursor: pointer;
  font-size: 16px !important;
  background-color: #ffcd00;
  font-family: "jokker-Regular";
  border-radius: 8px;
  width: 80px;
  height: 34px;
  border: #fff;
  position: inherit;
  margin-left: auto;
  /* top: -26px; */
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  color: #09143a;
  display: block;
  gap: 5px;
}

.viewTag {
  cursor: pointer;
}

.editIconSvg {
  width: 12px;
  height: 12px;
}

.mt-156 {
  margin-top: 156px !important;
}

.h-42 {
  height: 42px !important;
}

.radio-group {
  display: flex !important;
  flex-direction: row !important;
  column-gap: 50px !important;
}

.w-30 {
  width: 30%;
}

.checkboxLabel .css-28nq2i-MuiTypography-root {
  font-weight: 700;
}

.light-button {
  background-color: var(--off-white);
  color: var(--black-secondary);
}

.light-button:hover {
  color: var(--white);
}

.mb-90 {
  margin-bottom: 90px;
}

.gap-60 {
  gap: 60px;
}

.m-auto {
  margin: auto;
}

.m-unset {
  margin: unset;
}

.text-center {
  text-align: center;
}

.my-50 {
  margin-top: 50px;
  /* margin-bottom: 50px; */
}

.main-form-field .css-z3qrxz-MuiFormHelperText-root {
  margin: 0;
  padding: 0;
}

.css-12pivew-MuiButtonBase-root-MuiTab-root.Mui-selected {
  font-family: "Jokker-semibold";
}

.pl-24 {
  padding-left: 24px;
}

.pr-24 {
  padding-right: 24px;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.my-30 {
  margin: 30px 0;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.no-wrap {
  white-space: nowrap;
}

.ml-auto {
  margin-left: auto !important;
}

.pl-75 {
  padding-left: 75px;
}

.pr-75 {
  padding-right: 75px;
}

.border-l-r {
  border: 1px solid var(--header-bg);
  border-top: none;
  border-bottom: none;
}

.text-white {
  color: var(--white) !important;
}

.mt-40 {
  margin-top: 80px;
}

.mt-30 {
  margin-top: 30px !important;
}

.p-30-40 {
  padding: 30px 40px !important;
}

.dateRangePickerFilter {
  /* min-height: 20px !important; */
  padding: 17.5px 25px 17.5px 5px;
  border: 1px solid #9f9f9f !important;
  border-radius: 4px;
  width: 100%;
  font-size: 15px;
  background-color: var(--dashboard-bg);
}

.dateRangePickerFilter:focus.react-datepicker-ignore-onclickoutside {
  /* border-color: red !important; */
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background-color: #ffb600 !important;
}

.leadManagementTabs button {
  min-width: max-content !important;
}

.leadViewIconsActive {
  color: #d8d8d8 !important;
  cursor: pointer;
  margin: 10px;
}

.leadViewIconsInactive {
  color: #ffb600 !important;
  margin: 10px;
}

.activeTabLeadDetails {
  color: #000 !important;
}

.inactiveTabLeadDetails {
  color: #9f9f9f !important;
}

.leadDetailsContent {
  border-radius: 5px !important;
  /* padding: 20px !important; */
}

.leadsDetailsAccordion {
  background: var(--white) !important;
  color: rgba(0, 0, 0, 0.87);
  padding: 0 24px;
  cursor: pointer;
  box-shadow: none !important;
  margin-top: 20px;
}

.submitQuoteBtn {
  column-gap: 15px;
  box-shadow: 0px 5px 15px #b3b3b340 !important;
  border-radius: 8px !important;
  opacity: 1;
  color: #000 !important;
  background: #ffb600 !important;
  font-family: "jokker-Medium" !important;
  height: 45px !important;
  min-width: 140px !important;
}

.submitQuotesHeading {
  font-size: 24px;
  font-family: "jokker-Bold";
  /* margin-top: 25px; */
  color: var(--white);
}

.filterBtns {
  column-gap: 15px;
  box-shadow: 0px 5px 15px #b3b3b340 !important;
  border-radius: 32px !important;
  opacity: 1;
  color: #000 !important;
  background: #ffb600 !important;
  font-family: "NB" !important;
  width: 50%;
}

.disabledBtn {
  column-gap: 15px;
  box-shadow: 0px 5px 15px #b3b3b340 !important;
  border-radius: 32px !important;
  opacity: 1;
  color: #000 !important;
  background: grey !important;
  font-family: "NB" !important;
  height: 45px !important;
  min-width: 140px !important;
}

.uploadButton {
  width: 100%;
  min-width: 300px;
  height: 40px;
  border: none;
  border-radius: 50px !important;
  padding: 12px 30px;
  color: #fff;
  box-shadow: 0px 5px 15px #b3b3b340;
  background-color: #242424;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputFieldSpacing {
  margin-top: 10px;
  margin-bottom: 10px;
}

.personalDetailsUploadBtn {
  width: 80%;
  min-width: 300px;
  height: 40px;
  border: none;
  border-radius: 50px !important;
  padding: 12px 30px;
  color: #fff;
  box-shadow: 0px 5px 15px #b3b3b340;
  background-color: #242424;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.personalDetailsUpdateUploadBtnDiv .MuiTypography-root {
  font-size: 12px !important;
}

.personalDetailsUpdateUploadBtnDiv .uploadButton {
  width: 50% !important;
}

.formFieldSpacing {
  margin-top: 20px;
  margin-bottom: 20px;
}

.uploadQuoteButton {
  width: 100%;
  min-width: 200px;
  height: 40px;
  border: none;
  border-radius: 50px !important;
  padding: 12px 30px;
  color: #fff;
  box-shadow: 0px 5px 15px #b3b3b340;
  background-color: #242424;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadIcon {
  width: 150px;
  height: 130px;
  border: 1px solid #bfbfbf;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
}

.quoteHeadings {
  font-size: 18px !important;
  font-weight: bolder;
  color: #000 !important;
  font-family: "jokker-Bold";
}

.quoteValues {
  font-size: 15px !important;
}

.quoteAmount {
  font-size: 15px !important;
  background-color: #fff1d0;
  padding: 5px !important;
}
